import { BUCKET_API } from "commons/constants";

export const classic = [
  {
    src: BUCKET_API + "classic/classic-1.webp",
    width: "1920",
    height: "1281",
  },
  {
    src: BUCKET_API + "classic/classic-2.webp",
    width: "1920",
    height: "1281",
  },
  {
    src: BUCKET_API + "classic/classic-3.webp",
    width: "1920",
    height: "1280",
  },
  {
    src: BUCKET_API + "classic/classic-4.webp",
    width: "1920",
    height: "1280",
  },
  {
    src: BUCKET_API + "classic/classic-5.webp",
    width: "1920",
    height: "1440",
  },
  {
    src: BUCKET_API + "classic/classic-6.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "classic/classic-7.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "classic/classic-8.webp",
    width: "1920",
    height: "2560",
  },
];
