import "./Tile.css";

export default function Tile({ number, header, alignment = "left", children }) {
  return (
    <div className={`op-tile-container ${alignment}`}>
      {number > 0 ? <span>{number}</span> : ""}
      <div className={`op-tile-content ${alignment}`}>
        <span>{header}</span>
        {children}
      </div>
    </div>
  );
}
