import { useState } from "react";
import navMenuDef from "./nav-menu-def.json";
import "./NavMenu.css";

function NavMenuItem({
  id,
  title,
  url,
  active,
  mobile = false,
  submenu = false,
  onHoverHandler = null,
  onHoverEndHandler = null,
}) {
  return (
    <>
      <li
        className={`${mobile ? "mobile-" : ""}menu-item${
          Number(active) === id ? " hover" : ""
        }`}
        id={id}
        onMouseEnter={(e) => (onHoverHandler ? onHoverHandler(e) : void 0)}
        onMouseLeave={() => (onHoverEndHandler ? onHoverEndHandler() : void 0)}
      >
        <a className={`${mobile ? "mobile-" : ""}menu-link`} href={url}>
          {title}
          {submenu ? <span className="submenu-indicator"> </span> : ""}
        </a>
      </li>
    </>
  );
}

export default function NavMenu({ compact, location = "header" }) {
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [delayHandler, setDelayHandler] = useState(null);

  let menuHover = false,
    subMenuHover = false;

  function hoverStartMenuHandler(e) {
    menuHover = true;
    setSelectedMenu(e.currentTarget.id);
  }

  function hoverEndMenuHandler() {
    setDelayHandler(
      setTimeout(() => {
        if (!subMenuHover) {
          menuHover = false;
          setSelectedMenu(-1);
        }
      }, 1)
    );
  }

  function hoverStartSubMenuHandler() {
    clearTimeout(delayHandler);
    subMenuHover = true;
  }

  function hoverEndSubMenuHandler() {
    subMenuHover = false;
    setDelayHandler(
      setTimeout(() => {
        if (!menuHover) hoverEndMenuHandler();
      }, 1)
    );
  }

  return (
    <div className={`nav-menu nav-${location}-container`}>
      <ul key="main-nav" className={`main-nav-${location}`}>
        {navMenuDef.map((el, i) => {
          return (
            <NavMenuItem
              key={`${location}-main-${i}`}
              id={i}
              title={el.title}
              url={el.url}
              active={selectedMenu}
              submenu={el.submenu.length > 0}
              onHoverHandler={(e) => hoverStartMenuHandler(e)}
              onHoverEndHandler={() => hoverEndMenuHandler()}
            />
          );
        })}
      </ul>
      {selectedMenu !== -1 && navMenuDef[selectedMenu].submenu.length ? (
        <ul
          key="sub-nav"
          className={`sub-nav-${location}${compact ? " compact" : ""}`}
          onMouseEnter={() => hoverStartSubMenuHandler()}
          onMouseLeave={() => hoverEndSubMenuHandler()}
        >
          {
            //selectedMenu !== -1 &&
            navMenuDef[selectedMenu].submenu.map((el, i) => {
              return (
                <NavMenuItem
                  key={`${location}-sub-${selectedMenu}${i}`}
                  id={i}
                  pos={0}
                  title={el.title}
                  url={el.url}
                />
              );
            })
          }
        </ul>
      ) : (
        ""
      )}
    </div>
  );
}
