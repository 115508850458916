import { useState } from "react";

import { RxHamburgerMenu } from "react-icons/rx";
import {
  FaPhoneAlt,
  FaInstagram,
  FaFacebookSquare,
  FaTwitterSquare,
} from "react-icons/fa";

import "./Header.css";

import Logo from "../Logo/Logo";
import NavMenu from "../NavMenu/NavMenu";
import MobileNavMenu from "../NavMenu/MobileNavMenu/MobileNavMenu";
import { PHONE_NUMBER } from "commons/constants";
// import Button from "../Button/Button";

export default function Header({ compact, pos = "100px", mobile }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  return (
    <header className={`header${compact ? "-compact" : ""}`}>
      {compact ? (
        <>
          <div className="title-banner-compact">
            <RxHamburgerMenu
              style={{
                margin: "auto",
                color: "green",
                cursor: "pointer",
                zIndex: "999",
              }}
              size="50"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            />
            <Logo animated="true" link="true" />
            <a href="tel:512-749-3064" style={{ margin: "auto" }}>
              <FaPhoneAlt size={50} style={{ color: "var(--color-green)" }} />
            </a>
          </div>
          <MobileNavMenu key="mobile-nav" open={mobileMenuOpen} />
        </>
      ) : (
        <>
          <div className="title-banner">
            <div id="header-socials">
              <a
                href="https://www.instagram.com/onealcustomhomesllc/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram size={35} />
              </a>
              <a
                href="https://twitter.com/onealcustomhome"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitterSquare size={35} />
              </a>
              <a
                href="https://www.facebook.com/onealcustomhomes/"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookSquare size={35} />
              </a>
            </div>
            <Logo animated="true" link="true" />
            <div id="phone-button">
              <span onClick={() => setShowPhone(!showPhone)}>
                {showPhone ? (
                  PHONE_NUMBER
                ) : (
                  <>
                    <FaPhoneAlt size={15} color="white" /> Call Now
                  </>
                )}
              </span>
            </div>
          </div>
          <div className="nav-bar" style={{ top: `${pos}` }}>
            <NavMenu key="header-nav" compact={compact} location="header" />
          </div>
        </>
      )}
    </header>
  );
}
