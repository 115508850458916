import { MdConstruction } from "react-icons/md";

export default function PageNotFoundPage({ errorObj }) {
  document.title = `${errorObj.type} Error`;

  return (
    <div style={{ height: "400px", backgroundColor: "var(--color-grey-2)" }}>
      <div style={{ height: "100%", display: "grid", alignContent: "center" }}>
        <span
          style={{
            color: "var(--color-white)",
            fontFamily: "var(--font-roboto)",
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          <MdConstruction
            size="40"
            style={{
              display: "inline-block",
              top: ".26em",
              position: "relative",
              padding: "0 0.5rem",
            }}
          />
          {errorObj.msg}
        </span>
      </div>
    </div>
  );
}
