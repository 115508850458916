const { BUCKET_API } = require("commons/constants");

export const galleries = {
  bathroom: [
    {
      src: BUCKET_API + "bathroom/cover-bathroom.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom1.webp",
      width: "1920",
      height: "1280",
    },
    {
      src: BUCKET_API + "bathroom/bathroom2.webp",
      width: "1920",
      height: "1280",
    },
    {
      src: BUCKET_API + "bathroom/bathroom3.webp",
      width: "800",
      height: "540",
    },
    {
      src: BUCKET_API + "bathroom/bathroom4.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "bathroom/bathroom5.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom6.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom7.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom8.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom9.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "bathroom/bathroom10.webp",
      width: "559",
      height: "800",
    },
    {
      src: BUCKET_API + "bathroom/bathroom11.webp",
      width: "1635",
      height: "2180",
    },
    {
      src: BUCKET_API + "bathroom/bathroom12.webp",
      width: "1635",
      height: "2180",
    },
    {
      src: BUCKET_API + "bathroom/bathroom13.webp",
      width: "1920",
      height: "2560",
    },
  ],

  exterior: [
    {
      src: BUCKET_API + "exterior/cover-exterior.webp",
      width: "800",
      height: "533",
    },
    {
      src: BUCKET_API + "exterior/exterior1.webp",
      width: "1920",
      height: "1281",
    },
    {
      src: BUCKET_API + "exterior/exterior2.webp",
      width: "1920",
      height: "1281",
    },
    {
      src: BUCKET_API + "exterior/exterior3.webp",
      width: "1920",
      height: "2880",
    },
    {
      src: BUCKET_API + "exterior/exterior4.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "exterior/exterior5.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "exterior/exterior6.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "exterior/exterior7.webp",
      width: "1920",
      height: "1916",
    },
    {
      src: BUCKET_API + "exterior/exterior8.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "exterior/exterior9.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "exterior/exterior10.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "exterior/exterior11.webp",
      width: "1920",
      height: "1470",
    },
    {
      src: BUCKET_API + "exterior/exterior12.webp",
      width: "564",
      height: "800",
    },
    {
      src: BUCKET_API + "exterior/exterior13.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "exterior/exterior14.webp",
      width: "1920",
      height: "1280",
    },
    {
      src: BUCKET_API + "exterior/exterior15.webp",
      width: "1920",
      height: "1201",
    },
    {
      src: BUCKET_API + "exterior/exterior16.webp",
      width: "1920",
      height: "1440",
    },
  ],

  interior: [
    {
      src: BUCKET_API + "interior/cover-interior.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior1.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "interior/interior2.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior3.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior4.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior5.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior6.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "interior/interior7.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "interior/interior8.webp",
      width: "1920",
      height: "1077",
    },
    {
      src: BUCKET_API + "interior/interior9.webp",
      width: "1920",
      height: "2884",
    },
    {
      src: BUCKET_API + "interior/interior10.webp",
      width: "1920",
      height: "1297",
    },
    {
      src: BUCKET_API + "interior/interior11.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "interior/interior12.webp",
      width: "1920",
      height: "2560",
    },
  ],

  kitchen: [
    {
      src: BUCKET_API + "kitchen/cover-kitchen.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "kitchen/kitchen1.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "kitchen/kitchen2.webp",
      width: "1920",
      height: "1280",
    },
    {
      src: BUCKET_API + "kitchen/kitchen3.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "kitchen/kitchen4.webp",
      width: "1440",
      height: "1920",
    },
    {
      src: BUCKET_API + "kitchen/kitchen5.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "kitchen/kitchen6.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "kitchen/kitchen7.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "kitchen/kitchen8.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "kitchen/kitchen9.webp",
      width: "1920",
      height: "2560",
    },
    {
      src: BUCKET_API + "kitchen/kitchen10.webp",
      width: "1920",
      height: "1440",
    },
    {
      src: BUCKET_API + "kitchen/kitchen11.webp",
      width: "1920",
      height: "1281",
    },
    {
      src: BUCKET_API + "kitchen/kitchen12.webp",
      width: "1920",
      height: "1281",
    },
  ],
};
