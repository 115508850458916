// import { useState } from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import "./testimonial.css";

export default function Testimonial({ data, left = true, compact = false }) {
  // const [expand, setExpand] = useState({ compact });

  const pos = left ? "left" : "right";

  return (
    <div className={`testimonial-border ${pos}`}>
      <div
        className={`testimonial-container ${pos}${compact ? " compact" : ""}`}
      >
        {left ? (
          <>
            <div
              className={`testimonial-portrait ${pos}${
                compact ? " compact" : ""
              }`}
            >
              <img src={data.image} alt={`Portrait of ${data.source}`} />
            </div>
            {compact ? "" : <div className={`vertical-divider ${pos}`} />}
          </>
        ) : (
          ""
        )}
        <div className={`testimonial-content${compact ? " compact" : ""}`}>
          <FaQuoteLeft className="quote-start" size="25" />
          <div className={`testimonial-excerpt ${pos}`}>
            <span>{data.excerpt}</span>
          </div>
          <div className={`testimonial-full ${pos}`}>
            {data.fullTestimonial}
          </div>
          <FaQuoteRight className="quote-end" size="25" />
          <div className={`testimonial-author ${pos}`}>{data.source}</div>
        </div>
        {!left ? (
          <>
            {compact ? "" : <div className={`vertical-divider ${pos}`} />}
            <div
              className={`testimonial-portrait ${pos}${
                compact ? " compact" : ""
              }`}
            >
              <img src={data.image} alt={`Portrait of ${data.source}`} />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
