import { BUCKET_API } from "commons/constants";

export const modern = [
  {
    src: BUCKET_API + "modern/modern-1.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "modern/modern-2.webp",
    width: "1920",
    height: "1440",
  },
  {
    src: BUCKET_API + "modern/modern-3.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "modern/modern-4.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "modern/modern-5.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "modern/modern-6.webp",
    width: "1920",
    height: "2560",
  },
  {
    src: BUCKET_API + "modern/modern-7.webp",
    width: "1920",
    height: "1440",
  },
  {
    src: BUCKET_API + "modern/modern-8.webp",
    width: "1920",
    height: "1440",
  },
];
