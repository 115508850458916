// import { useState } from "react";

import "./testimonialspage.css";
import Testimonial from "components/Testimonial/Testimonial";
import { testimonials } from "components/Testimonial/testimonials-manifest.js";

import BackgroundImg from "components/BackgroundImg/BackgroundImg";

export default function ContactUsPage({ compact }) {
  console.log(testimonials[0]);

  return (
    <div className="te-page">
      <div className="te-landing">
        <div className="te-landing-content">
          <span className="te-banner-text">
            Our<span className={`${compact ? "compact" : ""}`}>Reviews</span>
          </span>
        </div>
        <BackgroundImg idOverride={5} />

        {/* <div className="te-landing-background">
          <div className="te-background-1" />
          <div className="te-background-2" />
        </div> */}
      </div>
      <div className="te-content-container">
        <div className="te-content">
          {testimonials.map((el, i) => {
            return (
              <Testimonial
                key={i}
                compact={compact}
                left={i % 2 === 0 ? true : false}
                data={testimonials[i]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
