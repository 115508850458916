// import { useState } from "react";

import { PHONE_NUMBER } from "commons/constants.js";
import Tile from "components/Tile/Tile.js";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";
import "./ourprocess.css";

export default function OurProcessPage({ compact }) {
  return (
    <div className="op-page">
      <div className="op-landing">
        <div className="op-landing-content">
          <span className="op-banner-text">
            Our <span>Process</span>
          </span>
        </div>
        <BackgroundImg idOverride={4} />

        {/* <div className="op-landing-background">
          <div className="op-background-1"></div>
        </div> */}
      </div>
      <div className="op-process-container">
        <div className="op-process-banner">
          <span>Our Process</span>
          <span>in 6 easy steps</span>
        </div>
        <div className="op-process-content">
          <div className={`op-process-tiles${compact ? " compact" : ""}`}>
            <Tile key={"process-tile-1"} number={1} header="Meet the Builder">
              <span>
                Have a face to face meeting with clients to discuss what, where
                and when they are looking to build. Also discuss what their
                budget is for project.
              </span>
            </Tile>
            <Tile
              key={"process-tile-2"}
              number={2}
              header="Obtain a Construction Loan"
            >
              <span>
                If all goes well at first meeting, clients will go to builders
                bank to qualify for loan to build house. They can either qualify
                for construction loan which is generally for people who can put
                land they own down as collateral or they can qualify for a
                mortgage in which case builder will carry the loan for the
                build.
              </span>
            </Tile>
            <Tile key={"process-tile-3"} number={3} header="Agree on a Budget">
              <span>
                Once having bank qualification, builder will start working on
                budget for build and will present a price per sq ft to clients
                within 2 to 3 weeks. If price per sq ft looks good to clients
                and they want to proceed with project we move to step 4.
              </span>
            </Tile>
            <Tile key={"process-tile-4"} number={4} header="Sign Contract">
              <span>
                Builder and client write up contract where we lay out scope of
                work, price and completion time for home.
              </span>
            </Tile>
            <Tile key={"process-tile-5"} number={5} header="Secure Financing">
              <span>
                Once paperwork has been signed, builder will submit project to
                bank for financing loan which will generally take 30 to 45 days.
              </span>
            </Tile>
            <Tile
              key={"process-tile-6"}
              number={6}
              header="Construction Begins"
            >
              <span>
                Once financing has been signed off on we start construction.
                From this point will take 4 to 6 months depending on complexity
                of home and weather.
              </span>
            </Tile>
          </div>
          <div className="op-process-background"></div>
        </div>
      </div>
      <div className="op-contact-us">
        <span>Let us create your dream home!</span>
        <span>
          Call us at <span>{PHONE_NUMBER}</span> for a quote.
        </span>
      </div>
    </div>
  );
}
