//Contact information
export const PHONE_NUMBER = "512-749-3064";
export const EMAIL = "onealcustomhomestx@gmail.com";
export const LOCATION = "Salado, TX";

//Public Key
export const MAIN_WEB3FORM_EMAIL_KEY = "a1432667-d2dd-46dd-99df-2d4158204971";
export const WEB3FORM_EMAIL_KEY = "b87f566a-af97-4da1-b7f5-79e21a0af607";

//UPDATE THIS
export const BUCKET_API =
  "https://pub-2bd93bb7d6a94ab7965efe79c6bdef52.r2.dev/";

//
