import "./Logo.css";

import { BUCKET_API } from "commons/constants";

export default function Logo({
  animated = false,
  compact = false,
  link = false,
}) {
  return (
    <div className={`logo${compact ? " compact" : ""}${link ? " link" : ""}`}>
      <img
        src={
          animated
            ? BUCKET_API + "LogoNoLoop-crop.gif"
            : BUCKET_API + "Logo.webp"
        }
        type={animated ? "image/gif" : "image/webp"}
        alt="O'Neal Custom Homes Logo"
        onClick={() => {
          if (link) window.location.href = "/";
        }}
      />
    </div>
  );
}
