import { EMAIL, LOCATION, PHONE_NUMBER } from "commons/constants";
import { FaInstagram, FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";

import "./Footer.css";

import Logo from "../Logo/Logo";
import NavMenu from "../NavMenu/NavMenu";

export default function Footer({ compact }) {
  return (
    <footer className="footer">
      <div className={`footer-container${compact ? " compact" : ""}`}>
        {compact ? (
          ""
        ) : (
          <div className="footer-left">
            <NavMenu key="footer-nav" compact={compact} location="footer" />
          </div>
        )}
        <div className="footer-center">
          <Logo link={true} />
          <span style={{ fontWeight: "Bold" }}>Contact</span>
          <span className="footer-contact-methods">
            <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
            <br />
            <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
          </span>
          <span>{LOCATION}</span>
        </div>
        <div className={`footer-right${compact ? " compact" : ""}`}>
          <div className={`footer-socials${compact ? " compact" : ""}`}>
            <a
              href="https://www.instagram.com/onealcustomhomesllc/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram size={50} />
            </a>
            <a
              href="https://twitter.com/onealcustomhome"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitterSquare size={50} />
            </a>
            <a
              href="https://www.facebook.com/onealcustomhomes/"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare size={50} />
            </a>
          </div>
          <div className="footer-details">
            <img
              src={require("images/equalOpHousing.webp")}
              alt="Equal Opportunity Housing icon"
            />
          </div>
          <span className="footer-copyright">
            © {new Date().getFullYear()} All Rights Reserved | O'Neal Custom
            Homes
          </span>
        </div>
      </div>
    </footer>
  );
}
