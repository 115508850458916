import { useState } from "react";
import { useLocation } from "react-router-dom";

import { MasonryPhotoAlbum } from "react-photo-album";
import "react-photo-album/masonry.css";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import "./gallery.css";
import { galleries } from "./gallery-manifest.js";
import { BUCKET_API } from "commons/constants";
import BackgroundImg from "components/BackgroundImg/BackgroundImg";

export default function GalleryPage() {
  const [index, setIndex] = useState(-1);
  const activeGallery = useLocation().pathname.split("/").at(-1);
  let photos = [];

  function populatePhotos(cat) {
    photos = cat.map((el) => ({
      src: el.src,
      width: el.width,
      height: el.height,
      alt: el.alt,
    }));
  }

  function renderSwitch() {
    switch (activeGallery) {
      case "interior":
        populatePhotos(galleries.interior);
        return (
          <>
            <span>Interior Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>As gorgeous on the inside as it is on the outside.</span>
          </>
        );
      case "exterior":
        populatePhotos(galleries.exterior);
        return (
          <>
            <span>Exterior Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Design with style. Design with a smile.</span>
          </>
        );
      case "bathroom":
        populatePhotos(galleries.bathroom);
        return (
          <>
            <span>Bathroom Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Best seat in the house.</span>
          </>
        );
      case "kitchen":
        populatePhotos(galleries.kitchen);
        return (
          <>
            <span>Kitchen Photos</span>
            <div className="g-gallery-info-divider"></div>
            <span>Your kitchen, your rules!</span>
          </>
        );
      default:
        return (
          <>
            <span>Gallery</span>
            <div className="g-gallery-info-divider"></div>
            <span>
              Here's a sample of some of our recent projects. Inspired by what
              you see? It's only the beginning!
            </span>
          </>
        );
    }
  }

  function GalleryHeader({ type }) {
    const active = activeGallery === type;

    return (
      <div
        id={`${type}`}
        className={`g-gallery-category${active ? " active" : ""}`}
      >
        <a href={`/gallery/${type}`}>
          <div className="g-category-overlay">
            <span>{type} Photos</span>
          </div>
          <img
            src={`${BUCKET_API}${type}/cover-${type}.webp`}
            alt="placeholder"
          />
        </a>
      </div>
    );
  }

  return (
    <div className="g-page">
      <div className="g-landing">
        <div className="g-landing-content">
          <div className="g-gallery-selector">
            <GalleryHeader key="bathroom" type="bathroom" />
            <GalleryHeader key="exterior" type="exterior" />
            <GalleryHeader key="interior" type="interior" />
            <GalleryHeader key="kitchen" type="kitchen" />
            <span className="g-gallery-label">
              Select one of the Galleries above to see more!
            </span>
          </div>
        </div>
        <BackgroundImg idOverride={2} />

        {/* <div className="g-landing-background">
          <div id="g-background-1" className="g-background"></div>
          <div id="g-background-1" className="g-background"></div>
        </div> */}
      </div>
      <div className="g-gallery-info">{renderSwitch()}</div>
      <div className="g-gallery-container">
        {activeGallery !== "gallery" && (
          <>
            <MasonryPhotoAlbum
              photos={photos}
              onClick={({ index }) => setIndex(index)}
            />
            <Lightbox
              slides={photos}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              carousel={{ finite: true }}
              plugins={[Fullscreen, Thumbnails, Zoom]}
            />
          </>
        )}
      </div>
    </div>
  );
}
