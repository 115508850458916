import { useState } from "react";

import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import { galleries } from "pages/gallery/gallery-manifest.js";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Testimonial from "../../components/Testimonial/Testimonial";
import { testimonials } from "components/Testimonial/testimonials-manifest.js";

import BackgroundImg from "components/BackgroundImg/BackgroundImg";

import "./homepage.css";
import ContactForm from "components/ContactForm/ContactForm";

let photos = populateGallery(galleries);

function populateGallery(g) {
  const tGalleries = Object.entries(galleries);
  var curGal;
  var retPhotos = [];

  var nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    ranNums = [],
    i = nums.length,
    j = 0;

  while (i--) {
    j = Math.floor(Math.random() * (i + 1));
    ranNums.push(nums[j]);
    nums.splice(j, 1);
  }

  for (i = 0; i < tGalleries.length; i++) {
    curGal = tGalleries[i][1];
    retPhotos = [
      ...retPhotos,
      curGal[ranNums[0]],
      curGal[ranNums[1]],
      curGal[ranNums[2]],
    ];
  }

  return retPhotos.sort((a, b) => 0.5 - Math.random());
}

export default function HomePage({ compact }) {
  const [index, setIndex] = useState(-1);

  return (
    <div className="homepage-content">
      <div className="landing-container">
        <div className="landing-content">
          <span className="slogan-text">
            Building Your <span style={{ color: "green" }}>#Future</span>
          </span>
          <div className="slogan-text-divider"></div>
          <span className="company-blurb">
            O'neal Custom Homes LLC is a custom home builder in the Central
            Texas Area specializing in building you your dream home.
          </span>
        </div>
        <BackgroundImg idOverride={6} />
        {/* <div className="landing-background">
          <div className="background-1"></div>
          <div className="background-2"></div>
        </div> */}
      </div>
      <div className="mission-statement">
        <h1>O'Neal Custom Homes</h1>
        <div className="mission-statement-divider"></div>
        <span>Since 2016</span>
        <span>
          ​O'neal Custom Homes started off with a simple desire to be a builder
          that stood apart from the rest. Now that may seem cliche, but it is
          something the owner takes to heart. He has been in the shoes of
          someone building their custom home and knows first hand that it can be
          a very stressful process. A primary objective of O'neal Custom Homes
          is to take as much stress out of the process as possible and build you
          a home that you and your family can start making memories in and enjoy
          for many years to come.
        </span>
      </div>
      {/* <div className="landing-facts">
        <div id="floorplan-count">
          <span>20+</span>
          <br />
          <span>Floorplan Options</span>
        </div>
        <div id="cutomization-count">
          <span>100s</span>
          <br />
          <span>of customizations</span>
        </div>
        <div id="houses-built">
          <span>10</span>
          <br />
          <span>Futures built</span>
        </div>
      </div> */}
      <div className="gallery-preview">
        <span>
          See Your <span>Potential</span>
        </span>
        <RowsPhotoAlbum
          photos={photos}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          slides={photos}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          carousel={{ finite: true }}
          plugins={[Fullscreen, Thumbnails, Zoom]}
        />
        <a href="/gallery">
          <button>View More in Gallery</button>
        </a>
      </div>
      <div className="testimonial-preview">
        <Testimonial
          key={2}
          compact={compact}
          left={false}
          data={testimonials[Math.floor(Math.random() * 12)]} //Rand 0-12
        />
      </div>
      <div className="homepage-contact-header">
        <span>Leave us your info</span>
        <span>And we’ll get right back to you.</span>
      </div>
      <div className={`homepage-contact${compact ? " compact" : ""}`}>
        <ContactForm compact={compact} />
      </div>
    </div>
  );
}
