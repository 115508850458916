const { BUCKET_API } = require("commons/constants");

export const backgrounds = [
  BUCKET_API + "headers/background-1.webp",
  BUCKET_API + "headers/background-2.webp",
  BUCKET_API + "headers/background-3.webp",
  BUCKET_API + "headers/background-4.webp",
  BUCKET_API + "headers/background-5.webp",
  BUCKET_API + "headers/background-6.webp",
  BUCKET_API + "headers/background-7.webp",
  BUCKET_API + "headers/background-8.webp",
];
