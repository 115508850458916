import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { FcCheckmark } from "react-icons/fc";
import { WEB3FORM_EMAIL_KEY } from "commons/constants";

import useWeb3Forms from "@web3forms/react";

import "./contactform.css";

export default function ContactForm({ compact }) {
  const { register, reset, handleSubmit, setValue } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState(null);
  const [name, setName] = useState("");

  const onHCaptchaChange = (token) => {
    setValue("h-captcha-response", token);
  };

  const { submit: onSubmit } = useWeb3Forms({
    access_key: WEB3FORM_EMAIL_KEY,
    settings: {
      from_name: "O'Neal Custom Homes",
      subject: `Inquiry from ${name}`,
      page: `${useLocation().pathname}`,
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setResult(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setResult(msg);
    },
  });

  function updateName(e) {
    console.log(e.target.value);
    setName(e.target.value);
    console.log(name);
  }

  return (
    <div
      id={`contact-form-container${compact ? " compact" : ""}${
        isSuccess ? " sent-success" : ""
      }`}
    >
      {isSuccess ? (
        <>
          {/* TODO improve sent confirmation*/}
          <FcCheckmark
            size="20"
            style={{ transform: "translateY(3px)" }}
          />{" "}
          <span>Thank You - Your message has been sent!</span>
        </>
      ) : (
        <>
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="checkbox"
              name="botcheck"
              className="hidden"
              style={{ display: "none" }}
            />
            <label id="name-label">
              Name*{" "}
              <input
                id="name-input"
                type="text"
                placeholder="First Last"
                {...register("name", {
                  required: true,
                  onChange: (e) => updateName(e),
                })}
              />
            </label>
            <label id="phone-label">
              Phone Number{" "}
              <input
                id="phone-input"
                type="text"
                placeholder="555-555-5555"
                {...register("phone", { required: false })}
              />
            </label>
            <label id="email-label">
              Email Address*{" "}
              <input
                id="email-input"
                type="text"
                placeholder="example@domain.com"
                {...register("email", { required: true })}
              />
            </label>
            <textarea
              id="message-input"
              placeholder="What would you like to ask us?"
              {...register("message", { required: true })}
            />
            {/* </label> */}
            <button id="submit-btn" type="submit">
              <span>Submit</span>
            </button>
            <div id="captcha-div">
              <HCaptcha
                sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
                reCaptchaCompat={false}
                onVerify={onHCaptchaChange}
              />
            </div>
          </form>
          <div id="result">{result}</div>
        </>
      )}
    </div>
  );
}
