import { useState, useEffect } from "react";

import { backgrounds } from "./background-manifest.js";
import "./background-img.css";

export default function BackgroundImg({ idOverride = 6 }) {
  const [curImg, setCurImg] = useState(idOverride);
  // const [nextImg, setNextImg] = useState(1);

  return (
    <div id="rotating-background-container">
      <div id="background-1" className="rotating-background">
        <img src={backgrounds[curImg]} alt="" />
      </div>
      {/* <div id="background-2" className="rotating-background">
        <img src={backgrounds[nextImg]} alt="" />
      </div> */}
    </div>
  );
}
